import React, { useEffect, useState } from "react";
import {
  Notification,
  SectionHelper,
  AddItem,
  MarketingPageLayoutContent,
  Button,
  Text,
  Page,
  Layout,
  Box,
  Tabs,
  Cell,
  Card,
  WixDesignSystemProvider,
  CardGalleryItem,
  FileUpload,
  FormField,
  Image,
  Loader,
  IconButton,
  Tooltip,
  ToggleSwitch,
} from "@wix/design-system";
import * as Icons from "@wix/wix-ui-icons-common";
import axios from "axios";
import FormData from "form-data";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
// import { t } from "i18next";
import { useTranslation } from "react-i18next";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const Cursor = () => {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);

  const cursorState = [
    { state: "Auto" },
    { state: "Default" },
    { state: "None" },
    { state: "Context-menu" },
    { state: "Help" },
    { state: "Pointer" },
    { state: "Wait" },
    { state: "Cell" },
    { state: "Crosshair" },
    { state: "text" },
    { state: "vertical-text" },
    { state: "alias" },
    { state: "copy" },
    { state: "move" },
    { state: "no-drop" },
    { state: "not-allowed" },
    { state: "all-scroll" },
    { state: "col-resize" },
    { state: "row-resize" },
    { state: "n-resize" },
    { state: "s-resize" },
    { state: "e-resize" },
    { state: "w-resize" },
  ];

  const [isClickedUpgrade, setIsClickedUpgrade] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);

  const [autoResize, setAutoResize] = React.useState(true);

  const [cursors, setCursors] = useState([]);
  const [selectedCursor, setSelectedCursor] = useState({});
  const [currentState, setCurrentState] = useState("");
  const [jsonCursors, setJsoncursors] = useState(null as any);
  const [uploadCursor, setUploadCursor] = useState(false);
  const [activeTabId, setActiveTabId] = useState(1);
  const [isModified, setIsModified] = useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.editorx.io/cursor/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function setSettings() {
    if (isOpening) {
      return;
    }
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify(selectedCursor),
    })
      .then(() => {
        setIsOpening(false);
        setOpen(!open);
        setIsModified(false);
      })
      .catch(() => {});
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setSelectedCursor(data?.config || {});
      })
      .catch(() => {});
  }

  useEffect(() => {
    fetch("https://certifiedcode.editorx.io/cursor/_functions/cursors")
      .then((res) => res.json())
      .then((data) => {
        setCursors(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getUploadUrl() {
    return fetch("https://certifiedcode.editorx.io/cursor/_functions/uploadurl")
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (selectedCursor) {
      const jsonText = JSON.stringify(selectedCursor, null, 2);
      setJsoncursors(jsonText);
      const cursorValue = Object.keys(selectedCursor)
        .map(
          (key) =>
            `url('${(selectedCursor as any)[key]}') ,${key.toLowerCase()}`
        )
        .join(", ");
      console.log(cursorValue);
      document.body.style.cursor = cursorValue;
      setIsModified(true);
    }
  }, [selectedCursor]);

  const handleCursor = (cursorPair: any) => {
    setSelectedCursor(cursorPair);
  };

  const resizeImage = (file: File): Promise<File> => {
    if (!autoResize) {
      return Promise.resolve(file);
    }

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new window.Image() as HTMLImageElement;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 32;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_SIZE) {
              height *= MAX_SIZE / width;
              width = MAX_SIZE;
            }
          } else {
            if (height > MAX_SIZE) {
              width *= MAX_SIZE / height;
              height = MAX_SIZE;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob as Blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          }, file.type);
        };

        img.src = event.target?.result as string;
      };

      reader.readAsDataURL(file);
    });
  };

  const handleUploadCursor = async (files: any, state: string) => {
    setUploadCursor(true);
    setCurrentState(state);
    const _file = files[0];
    const upload = await getUploadUrl();
    const uploadUrl = upload.uploadUrl;

    try {
      const resizedImage = await resizeImage(_file);
      const formData = new FormData();
      formData.append("file", resizedImage, resizedImage.name);

      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      var currentCursors = {};
      Object.assign(currentCursors, selectedCursor);
      (currentCursors as any)[state.toLowerCase()] =
        `https://static.wixstatic.com/` + uploadResponse.data[0].file_url;
      setSelectedCursor(currentCursors);
      setUploadCursor(false);
    } catch (error) {
      setUploadCursor(false);
      console.log(error);
    }
  };

  const CursorPreview = () => {
    return (
      <Cell span={4}>
        <Page.Sticky>
          <Card>
            <Card.Header
              title={t('cursor-preview-title')}
              subtitle={t('cursor-preview-subtitle')}
            />
            <Card.Divider />
            <Card.Content>
              <MarketingPageLayoutContent
                title={t('cursor-preview-content-title')}
                content={t('cursor-preview-content-content')}
                actions={<Button>{t('cursor-preview-content-button')}</Button>}
              />
            </Card.Content>
          </Card>
        </Page.Sticky>
      </Cell>
    );
  };

  const tabs = [
    { id: 1, title: t('tab-1-title') },
    { id: 2, title: t('tab-2-title') },
    window.location.href.includes("debug")
      ? { id: 3, title: t('tab-3-title') }
      : undefined,
  ].filter((v) => !!v);

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=7d5490bd-f540-467f-acde-9b6baa8544bd&redirectUrl=https://certifiedcode.editorx.io/cursor/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Loader size="large"></Loader>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show={isClickedUpgrade}>
        <Notification.TextLabel>
          {t('notification-title')}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            setInstance();
            setIsClickedUpgrade(false);
          }}
        >
          {t('notification-button')}
        </Notification.ActionButton>
        <Notification.CloseButton />
      </Notification>
      <Page>
        <Page.Header
          title={t('header-title')}
          subtitle={t('header-subtitle')}
          actionsBar={
            <Box direction="horizontal" gap="SP3">
              <Tooltip content={t('tooltip-content')}>
                <IconButton
                  skin="light"
                  as="a"
                  href="https://www.youtube.com/watch?v=mvvs6KYDnfs"
                  target="_blank"
                >
                  <Icons.Help></Icons.Help>
                </IconButton>
              </Tooltip>
              {/* <Button skin="inverted" prefixIcon={<Icons.Preview />}>Preview</Button> */}
              <Button
                disabled={!isUpgraded}
                onClick={setSettings}
                prefixIcon={isOpening ? undefined : <Icons.Confirm />}
              >
                {isOpening ? (
                  <Loader size="tiny" />
                ) : isModified ? (
                  t('save-button')
                ) : (
                  t('saved-button')
                )}
              </Button>
              <Button
                as="a"
                target="_blank"
                href={`https://www.wix.com/apps/upgrade/${"7d5490bd-f540-467f-acde-9b6baa8544bd"}?appInstanceId=${
                  instanceData?.instance?.instanceId
                }`}
                onClick={() => {
                  setIsClickedUpgrade(true);
                }}
                prefixIcon={
                  isUpgraded ? <Icons.PremiumFilled /> : <Icons.Premium />
                }
                skin={isUpgraded ? "premium-light" : "premium"}
              >
                {isUpgraded ? t('manage-button') : t('upgrade-button')}
              </Button>
            </Box>
          }
        />
        <Page.Tail>
          <Tabs
            items={tabs as any}
            type="compactSide"
            activeId={activeTabId}
            onClick={(tab: any) => setActiveTabId(tab.id)}
          />
        </Page.Tail>
        {activeTabId === 1 && (
          <Page.Content>
            <Layout>
              <Cell span={8}>
                <Box gap="SP2" direction="vertical">
                  <Layout>
                    {cursors.length === 0 ? (
                      <Cell span={8}>
                        <Loader size="large"></Loader>
                      </Cell>
                    ) : (
                      <></>
                    )}
                    {cursors.map((item: any, index: any) => {
                      return (
                        <Cell span={4}>
                          <CardGalleryItem
                            backgroundImageUrl={item.cursorPair.auto}
                            primaryActionProps={{
                              onClick: () => handleCursor(item.cursorPair),
                            }}
                            title={item.name}
                            // subtitle="Subtitle"
                            imagePlacement="side"
                          />
                        </Cell>
                      );
                    })}
                  </Layout>
                  <Text weight="thin" size="tiny" skin="disabled">
                  {t('footer-content')}{" "}
                    <a href="https://www.figma.com/community/file/827357960512204646/fantastic-cursors-and-where-to-find-them">
                    {t('footer-link')}
                    </a>
                    {t('footer-licensed')}
                  </Text>
                </Box>{" "}
              </Cell>
              <CursorPreview />
            </Layout>
          </Page.Content>
        )}
        {activeTabId === 2 && (
          <Page.Content>
            <Layout>
              <Cell span={8}>
                <Layout>
                  <Cell span={12}>
                    <FormField label={t('auto-size-cursor')}>
                      <ToggleSwitch
                        checked={autoResize}
                        onChange={() => setAutoResize(!autoResize)}
                      />
                    </FormField>
                  </Cell>
                  {!autoResize && (
                    <Cell span={12}>
                      <SectionHelper title={t('section-helper-title')}>
                      {t('section-helper-content')}
                      </SectionHelper>
                    </Cell>
                  )}
                  <Cell>
                    <Layout>
                      {cursorState.map((state, index) => {
                        return (
                          <Cell span={4} key={index}>
                            <FormField label={state.state}>
                              <FileUpload
                                multiple
                                accept=".jpeg,.gif,.png,.svg"
                                onChange={(e: any) =>
                                  handleUploadCursor(e, state.state)
                                }
                              >
                                {/* {({ openFileUploadDialog }) => (
                                  <Box direction="vertical" gap="SP2">
                                    <Image
                                      height={"32px"}
                                      width={"32px"}
                                      src={
                                        (selectedCursor as any)[
                                          (state?.state || "").toLowerCase()
                                        ]
                                      }
                                    />{" "}
                                    <AddItem
                                      // state={uploadCursor && currentState === state.state.toLowerCase() ? 'loading' : undefined}
                                      disabled={uploadCursor}
                                      size="tiny"
                                      onClick={openFileUploadDialog}
                                    >
                                       {t('upload-cursor-button')}
                                    </AddItem>
                                  </Box>
                                )} */}
                                {
                              ({ openFileUploadDialog }: { openFileUploadDialog: () => void }) => (
                                <Box direction="vertical" gap="SP2">
                                  <Image
                                    height={"32px"}
                                    width={"32px"}
                                    src={
                                      (selectedCursor as any)[
                                        (state?.state || "").toLowerCase()
                                      ]
                                    }
                                  />
                                  <AddItem
                                    disabled={uploadCursor}
                                    size="tiny"
                                    onClick={openFileUploadDialog}
                                  >
                                  {t('upload-cursor-button')}
                                  </AddItem>
                                </Box>
                              )
                            } 
                              </FileUpload>
                            </FormField>
                          </Cell>
                        );
                      })}
                      <Cell>
                        <Button
                          skin="destructive"
                          onClick={() => {
                            setSelectedCursor({});
                          }}
                          prefixIcon={<Icons.DeleteSmall />}
                        >
                          {t('delete-cursor-button')}
                        </Button>
                      </Cell>
                    </Layout>
                  </Cell>
                </Layout>
              </Cell>
              <Cell span={4}>
                <CursorPreview />
              </Cell>
            </Layout>
          </Page.Content>
        )}
        {activeTabId === 3 && (
          <Page.Content>
            <Cell>
              <Card>
                <Box className="boxCursors">
                  <pre className="jsonTextStyle">{jsonCursors}</pre>
                </Box>
              </Card>
            </Cell>
          </Page.Content>
        )}
      </Page>
    </WixDesignSystemProvider>
  );
};

export default Cursor;
